* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

$breakpoints: (
    'xs': 0,
    'sm': 480px,
    'md': 720px,
    'lg': 992px,
    'xl': 1200px,
    'xxl': 1254px,
);

@mixin xs {
    @media (min-width: map-get($breakpoints, 'xs')) {
        @content;
    }
}

@mixin sm {
    @media (min-width: map-get($breakpoints, 'sm')) {
        @content;
    }
}
@mixin md {
    @media (min-width: map-get($breakpoints, 'md')) {
        @content;
    }
}
@mixin lg {
    @media (min-width: map-get($breakpoints, 'lg')) {
        @content;
    }
}

@mixin xl {
    @media (min-width: map-get($breakpoints, 'xl')) {
        @content;
    }
}

@mixin xxl {
    @media (min-width: map-get($breakpoints, 'xxl')) {
        @content;
    }
}

@mixin breakpoint($bp: 1653px) {
    @media (min-width: $bp) {
        @content;
    }
}

.hide {
    @include xs {
        font-size: 2rem;
    }
}

.hide {
    @include sm {
        font-size: 2.5rem;
    }
}

.hide {
    @include md {
        font-size: 3rem;
    }
}

.hide {
    @include lg {
        font-size: 3.2rem;
    }
}

.hide {
    @include xl {
        font-size: 3.5rem;
    }
}

.big_thing {
    @include xs {
        font-size: 1.6rem;
    }

    @include sm {
        font-size: 2rem;
    }

    @include md {
        font-size: 2.2rem;
    }

    @include lg {
        font-size: 2.7rem;
    }

    @include xl {
        font-size: 3.7rem;
    }
}

.stealth_mode {
    @include xs {
        font-size: 1.2rem;
    }

    @include sm {
        font-size: 1.3rem;
    }

    @include md {
        font-size: 1.4rem;
    }

    @include lg {
        font-size: 1.5rem;
    }

    @include xl {
        font-size: 1.6rem;
    }
}

img {
    @include xs {
        height: 39px;
    }

    @include sm {
        height: 41px;
    }

    @include md {
        height: 51px;
    }

    @include lg {
        height: 61px;
    }
}
