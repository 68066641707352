.App {
    font-family: 'Heebo', sans-serif;
    background: url('./images/background.jpg');
    background-size: cover;
    min-height: 100vh;

    background-repeat: no-repeat;
}
.landing {
    display: flex;
    justify-content: space-between;
    padding: 30px;
}
img {
    object-fit: contain;
}

.big_text {
    position: absolute;
    width: fit-content;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -30%);
    font-family: 'Heebo', sans-serif;
    color: whitesmoke;
}
p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}

.intro {
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.intro_text {
    color: whitesmoke;
    font-family: 'Heebo', sans-serif;
    font-size: 2.5rem;
}
.slider {
    background-color: rgb(229, 187, 247);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
}

.hide {
    background: black;
    overflow: hidden;
}

.hide span {
    transform: translateY(100%);
    display: inline-block;
}

h4 {
    font-size: 1.5rem;
}

.policy_link {
    text-decoration: none;
    color: #fff;
    font-size: 1.2rem;

    &:hover {
        color: rgb(111, 192, 243);
    }
}
