.parentBody {
    .container {
        display: flex;
        justify-content: center;

        .icon {
            position: absolute;
            font-size: 2.8rem;
            color: #1890ff;
            top: 2px;
        }
        .headingBody {
            h2 {
                text-align: center;
                margin-top: 50px;
                font-size: 30px;
                font-family: 'Roboto Condensed', sans-serif;
                font-weight: 600;
            }
            p {
                text-align: center;
                font-family: 'Roboto Condensed', sans-serif;
                font-size: 14px;
            }
        }
    }
}
.buttonSend {
    position: absolute;
    border: none;
    background-color: #1890ff;
    width: 150px;
    height: 54px;
    font-weight: 500;
    border-radius: 25px;
    right: 38%;
    top: 4rem;
}

.modalBody .ant-modal-content {
    width: 700px;
    background-color: #f1f5f8;
    border-radius: 20px;
    z-index: 1;

    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.modalContent {
    background: url('../../images/B2.jpg');
    background-size: cover;
    min-height: 100vh;
    .ant-modal-mask {
        background-color: rgb(169 178 200 / 54%) !important;
        font-family: 'Heebo', sans-serif;
    }
}
