.header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 4rem;
  margin-bottom: 1rem;
}

.summary {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.3rem;
  font-size: 1rem;
  margin-left: 6rem;
  margin-right: 6rem;
}

.bottom-text {
  align-items: center;
  justify-content: center;
  padding: 1.3rem;
  font-size: 1rem;
  margin-left: 6rem;
  margin-right: 6rem;
}
